import React, { useState, useEffect, useMemo, useRef } from "react"
import Select from "react-select"
import Pagination from "../Pagination/Pagination"
import Index from "./Index"
import Button from "./Button"
import Statistics from "./statistics"
import Filters from "../../modules/Search/Advanced/Filters"
import Loader from "../Loader/Loader"
import { navigate } from "gatsby"

import { API_SEARCH } from "../../global/api"
import "./Indexes.scss"

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)

const IndexesSearch = ({ language, word, indexLang, filters }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  // Items per page
  const [pageSize, setPageSize] = useState(25)
  const [currentPage, setCurrentPage] = useState(1)
  // Sortnig
  const [sortType, setSortType] = useState("")
  // Active filters
  const [selectedFilters, setSelectedFilters] = useState(filters)

  const ref = useRef(null)

  const myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")

  // Featching data from API
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const controller = new AbortController()
      const signal = controller.signal
      const searchPhrase = convertStringToUnicodeHexArray(word)

      const bodyRaw = JSON.stringify({
        word: searchPhrase,
        index: indexLang.toLowerCase(),
        filters: filters,
      })
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: bodyRaw,
        redirect: "follow",
        signal,
      }

      await fetch(API_SEARCH, requestOptions)
        .then(response => {
          if (response.ok) {
            return response
          }
          throw Error(response.status)
        })
        .then(response => response.text())
        .then(result => {
          const data = JSON.parse(result)
          setData(data.results)
          setLoading(false)
        })
        .catch(error => {
          console.error(error)
        })
    }
    setSelectedFilters(filters)
    fetchData()
  }, [filters])

  useEffect(() => {
    scrollToRef(ref)
  }, [currentPage])

  // Handle sortnig
  useEffect(() => {
    let sortIndexes = [...data]
    const sortArray = () => {
      if (sortType === "AZ") {
        sortIndexes.sort((a, b) =>
          a.index_name.toLowerCase().localeCompare(b.index_name.toLowerCase())
        )
        setData(sortIndexes)
      } else if (sortType === "ZA") {
        sortIndexes.sort((a, b) =>
          b.index_name.toLowerCase().localeCompare(a.index_name.toLowerCase())
        )
        setData(sortIndexes)
      }
    }
    sortArray()
  }, [sortType])

  // Dividing indexes into pages
  const currentIndexes = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return data.slice(firstPageIndex, lastPageIndex)
  }, [data][currentPage])

  const handleFilterClick = () => {
    const queryParams = new URLSearchParams(window.location.search)
    let strSelectedFilters = selectedFilters.toString()
    queryParams.set("filters", strSelectedFilters)
    navigate(`/wyniki?${queryParams.toString().replace(/%2C/g, ",")}`)
  }

  const handleSelectChange = selectedOption => {
    setPageSize(parseFloat(selectedOption.value))
  }

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      position: "static",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      borderBottom: "1px solid transparent",
      color: isSelected ? "white" : "black",
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#8d1227"
        : isFocused
        ? "#e3e3e3"
        : undefined,
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#8d1227"
            : "#e3e3e3"
          : undefined,
      },
      padding: 8,
      fontSize: "16px",
    }),
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      width: 170,
      fontSize: "16px",
      borderColor: state.isFocused ? "#c8ad59" : "#a8a8a8",
      borderRadius: "7px 0px",
      height: 29,
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: "1px solid #a8a8a8",
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = "opacity 300ms"
      return { ...provided, opacity, transition }
    },
  }

  const options = [
    { value: "50", label: "50" },
    { value: "75", label: "75" },
    { value: "100", label: "100" },
    { value: "200", label: "200" },
  ]

  return (
    <div className="c-Indexes">
      <span className="c-Indexes__label">
        Wyniki wyszukiwania frazy:{" "}
        <span className={indexLang === "CS" ? "c-Indexes__label--cs" : ""}>
          {word}
        </span>
      </span>
      <div
        className={
          indexLang !== "PL"
            ? "c-Indexes__wrapper c-Indexes__wrapper--narrow"
            : "c-Indexes__wrapper"
        }
        ref={ref}
      >
        {loading ? (
          <Loader additionClass="c-Indexes__loader" />
        ) : (
          <div className="c-Indexes__wrapper--results">
            <div className="c-Indexes__options">
              <Button
                text="Sortuj A&nbsp;-&nbsp;Z"
                disabled={sortType === "AZ"}
                click={() => setSortType("AZ")}
              />
              <Button
                text="Sortuj Z&nbsp;-&nbsp;A"
                disabled={sortType === "ZA"}
                click={() => setSortType("ZA")}
              />
              <Select
                className="c-Indexes__select"
                options={options}
                defaultValue={pageSize}
                onChange={handleSelectChange}
                placeholder={"Liczba wyników"}
                styles={customStyles}
              />
            </div>
            {indexLang === "PL" ? (
              <div className="c-Indexes__filters">
                <Filters
                  setSelectedFilters={setSelectedFilters}
                  selectedFilters={selectedFilters}
                />
                <button
                  className="c-Indexes__filtersBtn"
                  onClick={() => handleFilterClick()}
                >
                  Zastosuj
                </button>
              </div>
            ) : (
              ""
            )}
            <div className="c-Indexes__content">
              <ul className="c-Indexes__itemList">
                {currentIndexes && currentIndexes.length > 0 ? (
                  currentIndexes.map((indexItem, index) => (
                    <li key={index} className="c-Indexes__item">
                      <Index
                        word={indexItem.index_name}
                        wordId={indexItem.word_id}
                        index={indexLang}
                        language={language}
                      />
                    </li>
                  ))
                ) : (
                  <li className="c-Indexes__alert">
                    <span>
                      Brak wyników. Spróbuj zmienić paramerty wyszukiwania.
                    </span>
                  </li>
                )}
              </ul>
            </div>
            <Statistics text={data.length} />
            <Pagination
              className="c-Indexes__pagination"
              currentPage={currentPage}
              totalCount={data.length}
              pageSize={pageSize}
              onPageChange={page => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </div>
  )
}

function convertStringToUnicodeHexArray(text) {
  const arrUnicodeHex = []
  for (let i = 0; i < text.length; i++) {
    arrUnicodeHex[i] = text.charCodeAt(i).toString(16)
  }

  return arrUnicodeHex
}

export default IndexesSearch
