import React, { useState, useEffect } from "react"

import "./Group.scss"

function Group({ id, filters, setSelectedQualifier, selectedFilters }) {
  const [name, setName] = useState()
  const [selected, setSelected] = useState([])

  // creating an array with qualifiers according to the group
  const qualifiers = filters.filter(function (filter) {
    return filter.group_id === id
  })

  useEffect(() => {
    if (selectedFilters) {
      setSelected(selectedFilters)
    }
  }, [selectedFilters])

  //adding headers to groups
  useEffect(() => {
    if (id === 1) {
      setName("chronologicznych")
    } else if (id === 2) {
      setName("frekwencyjnych")
    } else if (id === 3) {
      setName("specjalistycznych")
    } else if (id === 4) {
      setName("stylistycznych")
    }
  }, [])

  //setting selected qualifiers
  const handleSelectClick = id => {
    const exists = selected.includes(id)
    setSelected(selected => [...selected, id])
    if (exists) {
      setSelected(
        selected.filter(c => {
          return c !== id
        })
      )
    } else {
      setSelected(selected => [...selected, id])
    }
    setSelectedQualifier(id)
  }

  return (
    <div className="c-Group">
      <h2 className="c-Group__heading">{name}</h2>
      <ul className="c-Group__qualifierList">
        {qualifiers.map(qualifier => (
          <li
            className={`c-Group__qualifierItem ${
              selected.includes(qualifier.id)
                ? "c-Group__qualifierItem--active"
                : ""
            }`}
            key={qualifier.id}
          >
            <span onClick={() => handleSelectClick(qualifier.id)}>
              {qualifier.name}
            </span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Group
