import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"

import LayoutSubpage from "../layouts/layout_subpage"
import IndexesSearch from "../components/Indexes/IndexesSearch"

const IndexPage = () => {
  const [index, setIndex] = useState("")
  const [word, setWord] = useState("")
  const [filters, setFilters] = useState("")

  let location = useLocation()

  function zeroTest(element) {
    return element === 0
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    setWord(queryParams.get("szukaj"))
    setIndex(queryParams.get("index"))
    let filtersStr = queryParams.get("filters")
    let filtersArr = filtersStr.split(",").map(element => {
      return Number(element)
    })
    if (filtersArr.every(zeroTest)) {
      setFilters([])
    } else setFilters(filtersArr)
  }, [location])

  return (
    <LayoutSubpage title={`Wyniki wyszukiwania frazy: ${word}`}>
      <>
        {word && (
          <>
            <IndexesSearch
              language="pl"
              optionValueDesc="Liczba wyników"
              word={word}
              indexLang={index}
              filters={filters}
            />
          </>
        )}
      </>
    </LayoutSubpage>
  )
}

export default IndexPage
