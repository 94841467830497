import React, { useState, useEffect, useReducer } from "react"
import { API_QUALIFIERS } from "../../../global/api"

import Group from "./Group"
import "./Group.scss"

function Filters({ setSelectedFilters, selectedFilters }) {
  // Data form API - qualifiers and qualifiers groups
  const [groups, setGroups] = useState()
  const [qualifiers, setQualifiers] = useState()
  // const [selectedFiltersFromParent, setSelectedFiltersFromParent] = useState(selectedFilters)

  // Qualifiers
  const [selectedQualifier, setSelectedQualifier] = useState()

  // Reducer
  const initialState = { allSelected: selectedFilters || [] }

  function reducer(state, action) {
    switch (action.type) {
      case "increment":
        return { ...state, allSelected: [...state.allSelected, action.data] }
      case "decrement":
        return {
          ...state,
          allSelected: state.allSelected.filter(item => item !== action.data),
        }
      default:
        throw new Error()
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  // Featching data from API
  useEffect(() => {
    const fetchData = async () => {
      await fetch(API_QUALIFIERS)
        .then(response => {
          if (response.ok) {
            return response
          }
          throw Error(response.status)
        })
        .then(response => response.json())
        .then(data => {
          setGroups(data.groups)
          setQualifiers(data.results)
        })
        .catch(error => console.log(error))
    }
    fetchData()
  }, [])

  // Setting active qualifiers
  useEffect(() => {
    const exists = state.allSelected.includes(selectedQualifier)
    if (exists) {
      dispatch({ type: "decrement", data: selectedQualifier })
    } else {
      dispatch({ type: "increment", data: selectedQualifier })
    }
    let passFilters = state.allSelected.filter(c => {
      return c !== undefined
    })
    setSelectedFilters(passFilters)
    setSelectedQualifier()
  }, [selectedQualifier])

  return (
    <>
      {groups && qualifiers && (
        <>
          <h3 className="c-Filters__heading">
            Wyszukaj hasła po kwalifikatorach{" "}
          </h3>
          <ul>
            {groups.map((group, index) => (
              <li key={index}>
                <Group
                  id={group.id}
                  filters={qualifiers}
                  setSelectedQualifier={setSelectedQualifier}
                  selectedFilters={selectedFilters}
                />
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  )
}

export default Filters
